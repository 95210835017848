:root {
	--primary-main: hwb(213 18% 50%);
	--white: #ffffff;
	--fullSize: 100%;
}
.input-cust-wrapper input {
	height: var(--fullSize);
	width: var(--fullSize);
	font-weight: 500;
	color: var(--text-primary);
}

.dialog-common .modal-content {
	padding: 24px 16px !important;
}

.dialog-common .modal-content .icon-wrapper {
	display: flex;
}

.dialog-common .modal-content .icon-wrapper svg {
	color: var(--error-main);
	font-size: 48px;
	margin-bottom: 16px;
}

.dialog-common .modal-content .inner-title h5 {
	font-size: 14px;
	margin-bottom: 4px;
	color: var(--text-secondary);
}

.dialog-common .modal-content .body-content p {
	margin-bottom: 20px;
	color: var(--text-secondary);
}

.dialog-common h4 {
	font-size: 16px;
}

.MuiLink-root.userguide {
	position: fixed;
	top: calc(50% + 50px);
	right: 0;
	text-decoration: none;
	background-color: var(--primary-main);
	color: var(--white);
	padding: 8px 10px;
	border-radius: 5px 5px 0 0;
	transform: rotate(-90deg);
	transform-origin: 0 0;
	margin-right: -66px;
	font-weight: 500;
	font-size: 13px;
}
