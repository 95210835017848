main-loader {
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.75);
}

.main-fader {
    /*width: 100%;
    height: 100vh;*/
    /*position: absolute;*/
    /*background-color: rgba(0, 0, 0, 0.8);*/
}

    .main-fader .loader {
        /*position: fixed;*/
        /*top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);*/
    }

        .main-fader .loader svg {
            height: 150px;
            display: block;
            margin: 0 auto;
        }

            .main-fader .loader svg path {
                animation-duration: 1s;
                animation-name: pulse;
                animation-iteration-count: infinite;
                color: #26a380;
            }

                .main-fader .loader svg path.path-7 {
                    animation-delay: -1s;
                }

                .main-fader .loader svg path.path-6 {
                    animation-delay: -0.875s;
                }

                .main-fader .loader svg path.path-5 {
                    animation-delay: -0.75s;
                }

                .main-fader .loader svg path.path-4 {
                    animation-delay: -0.625s;
                }

                .main-fader .loader svg path.path-3 {
                    animation-delay: -0.5s;
                }

                .main-fader .loader svg path.path-2 {
                    animation-delay: -0.375s;
                }

                .main-fader .loader svg path.path-1 {
                    animation-delay: -0.25s;
                }

                .main-fader .loader svg path.path-0 {
                    animation-delay: -0.125s;
                }

@keyframes pulse {
    0% {
        opacity: 0.1;
    }

    30% {
        opacity: 0.8;
    }

    100% {
        opacity: 0.1;
    }
}
